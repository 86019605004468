import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { AdvancedBannerTop } from "./AdvancedBanner";
import { IoLogoAppleAppstore } from "react-icons/io5";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { IoLogoTiktok } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io5";

import './App.css';

function App() {
  return (
    <div className="App">
      <ParallaxProvider>
        <AdvancedBannerTop />
        <div className="center full">
          <h1 className="headline text-gray-300 px-48">L’histoire se déroule dans le village flottant fukkatsu, une créature du nom de Tanuki joue autour du maneki-neko, une statue traditionnelle japonaise qui apporte prospérité et chance au village fukkatsu. En jouant il fait tomber la cloche au bout du collier du chat, provoquant ainsi de nombreux dégâts sur le village. La créature devra alors récupérer cette cloche et ainsi sauver le village avec l'aide de l'esprit du maneki-neko.</h1>
        </div>
        <div className="center h-96">
          <div className='flex text-gray-300 text-4xl w-1/5 justify-evenly'>
            <div>
              <IoLogoAppleAppstore />
            </div>
            <div>
              <IoLogoGooglePlaystore />
            </div>
            <div>
              <IoLogoTiktok />
            </div>
            <div>
              <IoLogoTwitter />
            </div>
            <div>
              <IoLogoYoutube />
            </div>
          </div>
        </div>
      </ParallaxProvider>
    </div>
  );
}

export default App;
