import { ParallaxBanner } from "react-scroll-parallax";

export const AdvancedBannerTop = () => {
  const background = {
    image: "cascade.webp",
    translateY: [8, 8],
    translateX: [-15, -15],
    opacity: [1, 0.3],
    scale: [0.7, 0.7, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const title = {
    image: "fukkatsu.webp",
    translateY: [8, 8],
    translateX: [-15, -15],
    opacity: [1, 0.3],
    scale: [0.3, 0.7, "easeOutCubic"],

    shouldAlwaysCompleteAnimation: true,
  };

  const sky = {
    image: "sky.webp",
    translateY: [0, 8],
    translateX: [-15, -15],
    opacity: [1, 0.3],
    scale: [0.9, 0.7, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const headline = {
    translateY: [0, 30],
    translateX: [-10, 30],
    scale: [0.5, 0.5, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="inset center">
        <img src="fukkatsu.webp" />
        {/* <h1 className="headline white">Fukkatsu</h1> */}
      </div>
    ),
  };

  const foreground = {
    image: "montain.webp",
    translateY: [0, 15],
    translateX: [50, 30],
    scale: [1, 1.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const birds = {
    image: "oiseaux.webp",
    translateY: [0, 0],
    translateX: [0, 0],
    scale: [0.4, 0.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  // const bush = {
  //   image: "buisson.webp",
  //   translateY: [5, 5],
  //   translateX: [-40, -35],
  //   scale: [0.3, 0.4, "easeOutCubic"],
  //   shouldAlwaysCompleteAnimation: true,
  // };

  const clouds = {
    image: "clouds.webp",
    translateY: [-40, 15],
    translateX: [-40, 80],
    scale: [0.1, 1.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const gradientOverlay = {
    opacity: [0.1, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: <div className="gradient inset" />,
  };

  return (
    <ParallaxBanner
      layers={[
        sky,
        background,
        headline,
        // birds,
        // clouds,
        // title,
        foreground,
        gradientOverlay,
      ]}
      className="full"
    />
  );
};
